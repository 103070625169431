@use '@/styles/utils/mixins.scss' as *;
.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 0 10rem;

  @include mob() {
    padding: 0 0.94rem;
  }

  .description {
    text-align: center;
    margin: 0 auto;
    max-width: 47.5rem;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 400;
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
}
